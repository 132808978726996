<template>
	<div>
		<!-- <TitleMore
      :title="['红十字主题活动']"
      @handleMore="handleMore"
    ></TitleMore>
    <div class="baseCard homeThree">
      <el-carousel style="width:48%">
        <el-carousel-item
          v-for="item in dataListBanner"
          :key="item.id"
        >
          <template v-if="item.smallImage">
            <div class="f-w-full f-h-full">
              <img
              style="width:100%;height:100%"
                :src="`${item.smallImage}`+'!550x370'"
                alt=""
                @click="$goto('/detailShow',{id:item.id})"
              >
            </div>
          </template>
        </el-carousel-item>
      </el-carousel>
      <div class="homeThreeRight">
        <div
          class="itemText"
          v-for="item in dataList"
          :key="item.id"
          @click="$goto('/detailShow',{id:item.id})"
        >
          <p>
            <img
              src="../../assets/img/list-little.png"
              alt=""
            >
            <span class="itemTextContent">{{item.title}}</span>
          </p>
          <p class="itemTextTime">{{item.publishDate}}</p>
        </div>
      </div>
    </div> -->
		<div class="homeLeftMenu">
			<div class="homeLeftMenuItem" v-for="(item, index) in leftMenu" :key="index" @click="$goto(item.path)">
				<img style="width:30px" :src="item.img" alt="" />
				<p style="width:40px;text-align:center;margin-top:10px">{{ item.title }}</p>
			</div>
		</div>
		<!-- <TitleMore :title="['应急救护培训']" :isMore="false"></TitleMore>
		<div class="baseCard trainCard">
			<p style="font-size: 24px;font-weight: bold;color: #555454;line-height: 48px;text-align:center">{{ trainData.title }}</p>
			<TitleLine width="200px"></TitleLine>
			<div class="trainContentCard" v-html="trainData.info"></div>
		</div> -->
		<TitleMore :title="['应急救护培训']" :isMore="false"></TitleMore>
		<div class="baseCard ercodeItemCard">
			<p style="font-size: 24px;font-weight: bold;color: #555454;line-height: 48px;text-align:center">应急救护培训简介</p>
			<TitleLine width="200px"></TitleLine>
			<p style="margin: 20px 0;" v-html="jhpxData.info"></p>
			<div style="width: 100%;display: flex;flex-wrap: wrap;justify-content: center;align-items: center;">
				<p class="p">{{ jhpxData.title }}</p>
				<img :src="jhpxData.qrcode" style="width: 250px;height: 250px;" />
				<a :href="jhpxData.link" target="_blank">{{ jhpxData.link_add }}</a>
			</div>
		</div>
		<TitleMore :title="['造血干细胞捐献']" :isMore="false"></TitleMore>
		<div class="baseCard ercodeItemCard">
			<p style="font-size: 24px;font-weight: bold;color: #555454;line-height: 48px;text-align:center">四川省造血干细胞捐献基本情况</p>
			<TitleLine width="200px"></TitleLine>
			<p style="margin: 20px 0;" v-html="zxueData.info"></p>
			<div style="width: 100%;display: flex;flex-wrap: wrap;justify-content: center;align-items: center;">
				<p class="p">{{ zxueData.title }}</p>
				<img :src="zxueData.qrcode" style="width: 250px;height: 250px;" />
				<a :href="zxueData.link" target="_blank">{{ zxueData.link_add }}</a>
			</div>
		</div>
		<TitleMore :title="['遗体和人体器官（组织）捐献']" :isMore="false"></TitleMore>
		<div class="baseCard ercodeItemCard">
			<p style="font-size: 24px;font-weight: bold;color: #555454;line-height: 48px;text-align:center">四川省遗体和人体器官（组织）捐献基本情况</p>
			<TitleLine width="200px"></TitleLine>
			<p style="margin: 20px 0;" v-html="qiguanData.info"></p>
			<div style="width: 100%;display: flex;flex-wrap: wrap;justify-content: center;align-items: center;">
				<p class="p">{{ qiguanData.title }}</p>
				<img :src="qiguanData.qrcode" style="width: 250px;height: 250px;" />
				<a :href="qiguanData.link" target="_blank">{{ qiguanData.link_add }}</a>
			</div>
		</div>
	</div>
</template>

<script>
import TitleMore from '@components/TitleMore';
import TitleLine from '@components/TitleLine';
import apiUrls from '@api';
export default {
	data() {
		return {
			dataListBanner: [],
			dataList: [],
			trainData: {},
			zxueData: {},
			qiguanData: {},
			jhpxData: {},
			leftMenu: [
				{
					img: require('../../assets/img/help.png'),
					title: '我要求助',
					path: '/help'
				},
				{
					img: require('../../assets/img/idonate.png'),
					title: '我要捐赠',
					path: '/idonate'
				},
				{
					img: require('../../assets/img/complaint.png'),
					title: '我要参与',
					path: '/complain'
				},
				{
					img: require('../../assets/img/bossemail.png'),
					title: '会长信箱',
					path: '/email'
				}
			]
		};
	},

	components: {
		TitleMore,
		TitleLine
	},

	created() {
		this.getList('zthd');
		this.getTrain('yjjhph');
		this.getTrain('zxgxbjx');
		this.getTrain('ytqgjmjx');
		this.getTrain('yjjhpxewm');
	},

	methods: {
		handleMore() {
			this.$router.push({
				path: '/textDetail',
				query: { name: '红十字主题活动', type: 'zthd' }
			});
		},
		getList(type) {
			apiUrls.selfDynamicList({ node: type, page: 0, size: 15 }).then(res => {
				this.dataListBanner = [];
				this.dataList = [];
				res.results.data.forEach(item => {
					if (item.smallImage && this.dataListBanner.length < 3) {
						this.dataListBanner.push(item);
					} else {
						this.dataList.push(item);
					}
				});
			});
		},
		removebg: function(value) {
			if (!value) return '';
			let arr = value.split('background-color:rgb( 255 , 255 , 255 )');
			let str = arr.join('');
			return str;
		},
		getTrain(type) {
			apiUrls.trainNum({ node: type }).then(res => {
				if (type == 'yjjhph') {
					this.trainData = res.results.data;
					this.trainData.info = this.removebg(res.results.data.info);
				} else if (type == 'zxgxbjx') {
					this.zxueData = res.results.data;
					this.zxueData.link = this.zxueData.link_add.split('网')[1];
				} else if (type == 'ytqgjmjx') {
					this.qiguanData = res.results.data;
					this.qiguanData.link = this.qiguanData.link_add.split('网')[1];
				} else {
					console.log(res);
					this.jhpxData = res.results.data;
					this.jhpxData.link = this.jhpxData.link_add.split('网')[1];
				}
			});
		}
	}
};
</script>
<style lang="less" scoped>
.p {
	width: 100%;
	text-align: center;
	font-size: 18px;
	font-weight: bold;
	color: #d1292e;
	line-height: 48px;
}
.homeThree {
	display: flex;
	height: 480px;
	/deep/ .el-carousel__container {
		height: 480px;
	}
	.homeThreeRight {
		width: 48%;
		margin-left: 4%;
		.itemText {
			padding: 12px 0px;
		}
		.htrContent {
			font-size: 14px;
			color: #958d8d;
			border-bottom: 1px dotted #dcdcdc;
			padding: 10px 0px;
			line-height: 30px;
		}
	}
}
.trainCard {
	display: flex;
	flex-direction: column;
	justify-content: center;
	.trainContentCard {
		width: 1038px;
		height: auto;
		margin: 20px auto;
		border-radius: 8px;
		box-shadow: 0px 0px 18px 0px #fad5d6;
		font-size: 16px;
		color: #666666;
		line-height: 36px;
		padding: 20px 40px;
		background: url('../../assets/img/bg-left.png') no-repeat;
		background-size: 20% 130%;
	}
	.applyBtn {
		width: 30%;
		margin: 0 auto;
		text-align: center;
		color: #ffffff;
		text-decoration: none;
		line-height: 40px;
	}
}
.ercodeCard {
	width: 1200px;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	.ercodeCardItem {
		width: 32%;
		.ercodeItemCard {
			width: calc(100% - 120px);
			padding: 40px 60px;
			img {
				width: 100%;
				height: 100%;
			}
		}
	}
}
.homeLeftMenu {
	position: fixed;
	top: 40%;
	left: 0px;
	border-radius: 0px 24px 24px 0px;
	background: #ffffff;
	box-shadow: 0px 6px 18px 0px rgba(14, 14, 14, 0.27);
	padding: 10px;
	z-index: 100;
	.homeLeftMenuItem {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin: 30px 0px;
		cursor: pointer;
	}
	.homeLeftMenuItem:first-child {
		margin-top: 10px;
	}
	.homeLeftMenuItem:last-child {
		margin-bottom: 10px;
	}
}
.titleMore {
	width: 100% !important;
}
</style>
